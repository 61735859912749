import axios from "@/axios.js";

export default {
  async fetchSeats({ commit }) {
    try {
      const { data } = await axios.get(`/shuttle/seat-arrangement`);
      commit("setSeats", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSeat({ commit }, params) {
    try {
      const { data } = await axios.get(`/shuttle/seat-arrangement/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeSeat({ commit }, params) {
    try {
      const { data } = await axios.post(`/shuttle/seat-arrangement`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateSeat({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/shuttle/seat-arrangement${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeSeat({ dispatch }, params) {
    try {
      const { data } = await axios.delete(
        `/shuttle/seat-arrangement/${params}`
      );
      dispatch("fetchSeats");
      return { success: true };
    } catch (error) {
      return {
        success: false,
        message: error.data ? error.data.msg_status : error.message,
      };
    }
  },
};
