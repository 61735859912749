<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Seat Arrangement</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Seat Number (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import seatStore from "@/store/seat";

export default {
  name: "BedEdit",

  metaInfo: {
    title: "Edit Seat Arrangement",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
  },

  data: () => ({
    payload: {
      name: "",
    },
  }),

  methods: {
    async getData() {
      const response = await this.$store.dispatch(
        "seat/fetchSeat",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.append("_method", "PUT");
      const response = await this.$store.dispatch("seat/updateSeat", {
        id: this.decryptedID,
        body: formData,
      });
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/seat-arrangement");
    },
  },

  created() {
    if (!seatStore.isRegistered) {
      this.$store.registerModule("seat", seatStore);
      seatStore.isRegistered = true;
    }

    this.getData();
  },
};
</script>

<style></style>
